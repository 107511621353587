
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'InfoMessage',
  props:{
    text:{
      type: String,
      default: ''
    },
    messageType:{
      type: String,
      default: 'info'
    }
  }
})
