
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Counter from '~/components/Counter/Counter.vue'
import Spinner from '@/components/StudyCreation/Spinner.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import store from '@/store'
import { criteriaHasQuota, getQuestionCriteria, getCriteriaValue, getViabilityIcon, viabilityActivities } from '@/utils/viability.ts'
import { ProductTypes } from '@/utils/constants';
export default defineComponent({
  name : 'ViabilityCalculator',
  components:{
    Counter,
    Spinner,
    Separation
  },
  props:{
    surveyData:Object,
    watLabData:{
      type: Object,
      default:() => {}
    },
    price:{
      type: Number,
      default: 0
    },
    loading:{
      type: Boolean,
      default: false
    },
    showSurveyCalculator:{
      type: Boolean,
      default: false
    },
    showWatLabCalculator:{
      type: Boolean,
      default: false
    },
    showUsersType: {
      type: Boolean,
      default: false
    },
    studyType: {
      type: String,
      default: ''
    },
    isMultiCommunityPlan: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapGetters([
      'criteriables',
      'WatLabPlans',
      'countries'
    ]),
    getViabilityCountry(){
      const surveyData: any = this.surveyData
      if(surveyData.country == undefined) return this.$t(this.countries[0].value)
      let lang = surveyData.country.toUpperCase()
      const country = this.countries.filter((c: any) => c.secondaryValue.toUpperCase() == lang)[0]
      return this.$t(country.value)
    },
    activitiesAllowed(){
      const vm = this
      const planActivities = this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.activityTypes
      if(planActivities == undefined) return

      return this.viabilityActivities.filter(function(va: any, index: any){return planActivities.includes(va.type)})
    },
    watLabDuration(){
      if(this.watLabData.duration == 12) return this.$t('suite_1_year')
      else if(this.watLabData.duration == 1) return this.$t('suite_1_month')
      return this.watLabData.duration + ' ' + this.$t('suite_months')
    },
    watLabSupport(){
      const vm = this
      return this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.supportType
    },
    watLabMaxParticipants(){
      const vm = this
      return this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.maxActiveParticipants
    },
    watLabFocusGroupNum(){
      const vm = this
      return this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.videochatNum
    },
    usersOrigin(){
      const origin = this.watLabData.usersOrigin
      return origin == 'WAT' ? 'WAT Community' : this.$t('suite_watlab_step3_origin_own2')
    },
    showDevices(){
      return this.studyType == ProductTypes.WAT_UX || this.studyType == ProductTypes.WAT_UX_QUANTI || this.studyType == ProductTypes.CLICK_TEST
    },
    showWatUxOptions(){
      return this.studyType == ProductTypes.WAT_UX
    }
  },
  data(){
    return{
      componentTop: '',
      viabilityActivities: viabilityActivities,
      showDescription: false,
      afterContent: "Cuotas seleccionadas"
    }
  },
  methods:{
    scroll(){
      if(window.innerWidth > 875){
        if(window.scrollY > 165) this.componentTop = '-150px'
        else this.componentTop = '0'
      }
    },
    getViabilityIcon(criteria: any){
      return getViabilityIcon(criteria, this.surveyData?.quotas)
    },
    criteriaHasQuota(criteria: any){
      return criteriaHasQuota(this.surveyData?.quotas, criteria)
    },
    getCriteriaValue(criteria: any){
      return getCriteriaValue(criteria, this.criteriables)
    },
    isActivityEnabled(activityType: any){
      const vm = this
      const planActivities = this.WatLabPlans.map(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp.activityTypes
      })
      return planActivities.includes(activityType)
    },
    openDescription(){
      this.showDescription = !this.showDescription
    }
  },
  mounted(){
    let vm = this
    window.onscroll = function(event) {
      vm.scroll()
    };
  }
})
