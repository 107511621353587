export const getQuestionCriteria = function(categories: any, categoryId: any, questionId: any){
  const category: any = categories.filter(function(c: any){return c.identifier == categoryId})[0]
  if(category == undefined) return null
  return category.questions.filter(function(q: any){ return q.id == questionId })[0]
}

export const getQuestionCriteriaValue = function(categories: any, categoryId: any, questionId: any, valueId: any){
  const question: any = getQuestionCriteria(categories, categoryId, questionId)
  const value: any = question.values.filter(function(v: any){return v.id == valueId})[0]
  return value
}

export const criteriaHasQuota = function(quotas: any, criteria: any){
  let hasQuota = false
  if(quotas != undefined){
    for(let q of quotas){
      if(criteria.genderCriteria && q.selectionBlocks[0].genderCriteria) hasQuota = true
      if(criteria.ageCriteria && q.selectionBlocks[0].ageCriteria) hasQuota = true
      if(criteria.provinceCriteria && q.selectionBlocks[0].provinceCriteria) hasQuota = true
      if(criteria.regionCriteria && q.selectionBlocks[0].regionCriteria) hasQuota = true
      if(criteria.nielsenCriteria && q.selectionBlocks[0].nielsenCriteria) hasQuota = true
      if(criteria.categoryCriteria && q.selectionBlocks[0].categoryCriteria && q.selectionBlocks[0].categoryCriteria[0].categoryId == criteria.categoryCriteria[0].categoryId && q.selectionBlocks[0].categoryCriteria[0].questionId == criteria.categoryCriteria[0].questionId) hasQuota = true
    }
  }
  return hasQuota
}

export const getQuotaIndex = function(quotas: any, criteria: any){
  let index = 0
  let quotaIndex = 0
  if(quotas != undefined){
    for(let q of quotas){
      if(criteria.genderCriteria && q.selectionBlocks[0].genderCriteria) quotaIndex = index
      if(criteria.ageCriteria && q.selectionBlocks[0].ageCriteria) quotaIndex = index
      if(criteria.provinceCriteria && q.selectionBlocks[0].provinceCriteria) quotaIndex = index
      if(criteria.regionCriteria && q.selectionBlocks[0].regionCriteria) quotaIndex = index
      if(criteria.nielsenCriteria && q.selectionBlocks[0].nielsenCriteria) quotaIndex = index
      if(criteria.categoryCriteria && q.selectionBlocks[0].categoryCriteria && q.selectionBlocks[0].categoryCriteria[0].categoryId == criteria.categoryCriteria[0].categoryId && q.selectionBlocks[0].categoryCriteria[0].questionId == criteria.categoryCriteria[0].questionId) quotaIndex = index
      index++
    }
  }
  return quotaIndex
}

export const getCriteriaValue = function(criteria: any, categories: any){
  if(criteria.genderCriteria) return 'Género'
  if(criteria.ageCriteria) return 'Edad'
  if(criteria.provinceCriteria) return 'Provincia'
  if(criteria.regionCriteria) return 'Comunidad autónoma'
  if(criteria.nielsenCriteria) return 'Zona geográfica'
  if(criteria.categoryCriteria) return getQuestionCriteria(categories, criteria.categoryCriteria[0].categoryId, criteria.categoryCriteria[0].questionId).clientText
}

export const getViabilityIconName = function(icon: any){
      let value = 'ok'
      if(icon == 'GREEN') value = 'ok'
      else if(icon == 'AMBER') value = 'warning'
      else if(icon == 'RED') value = 'error'
      return value
}

export const getViabilityIcon = function(criteria: any, quotas: any){
  let valueToReturn = getViabilityIconName(criteria.icon)
  if(criteriaHasQuota(quotas, criteria) && quotas != undefined){
    for(let quota of quotas){
      let q:any = quota
      if(criteria.genderCriteria && q.selectionBlocks[0].genderCriteria) valueToReturn = getViabilityIconName(q.icon)
      if(criteria.ageCriteria && q.selectionBlocks[0].ageCriteria) valueToReturn = getViabilityIconName(q.icon)
      if(criteria.provinceCriteria && q.selectionBlocks[0].provinceCriteria) valueToReturn = getViabilityIconName(q.icon)
      if(criteria.regionCriteria && q.selectionBlocks[0].regionCriteria) valueToReturn = getViabilityIconName(q.icon)
      if(criteria.nielsenCriteria && q.selectionBlocks[0].nielsenCriteria) valueToReturn = getViabilityIconName(q.icon)
      if(criteria.categoryCriteria && q.selectionBlocks[0].categoryCriteria && q.selectionBlocks[0].categoryCriteria[0].categoryId == criteria.categoryCriteria[0].categoryId && q.selectionBlocks[0].categoryCriteria[0].questionId == criteria.categoryCriteria[0].questionId) valueToReturn = getViabilityIconName(q.icon)
    }
  }
  return valueToReturn
}

export const viabilityActivities = [
    {name:'Número máximo de participantes', faIcon:'fas fa-users'},
    {name:'Soporte técnico', faIcon:'far fa-envelope'},
    {name:'Actividad Debate', icon:'icon-chat-icon', type:'WAT_CHAT'},
    {name:'Actividad Tarea', icon:'icon-pencil-icon', type:'WAT_TASK'},
    {name:'Actividad Opinión', icon:'icon-bubble-icon', type:'WAT_OPINION'},
    {name:'Actividad Votación', icon:'icon-hand-icon', type:'WAT_POLL'},
    {name:'Actividad Encuesta', icon:'icon-star-icon', type:'WAT_SURVEY'},
    {name:'Actividad Videollamada', icon:'icon-video-icon', type:'VIDEOCHAT'},
    {name:'Actividad Entrevista moderada', icon:'icon-user-square-icon', type:'WAT_CHAT_ONLINE'},
    {name:'Actividad WAT UX', icon:'icon-pointer-icon', type:'WAT_UX'},
    {name:'Actividad Mapa de calor', icon:'icon-eye-icon', type:'EL_HEATMAP'}
]
