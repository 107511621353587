import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c7ceaa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fas fa-check-circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["SelectionBox", {'selected':_ctx.selected, 'selectable':_ctx.selectable}])
  }, [
    _withDirectives(_createElementVNode("i", _hoisted_1, null, 512), [
      [_vShow, _ctx.selected]
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
  ], 2))
}