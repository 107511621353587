
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'SelectionBox',
  props:{
    text:{
      type:String,
      default:" "
    },
    selected:{
      type: Boolean,
      default: false
    },
    selectable:{
      type: Boolean,
      default: true
    }
  }
})
