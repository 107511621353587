
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'Spinner',
  props:{
    type:{
      type: String,
      default: 'md'
    }
  }
})
