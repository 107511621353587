
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'ProgressModal',
  props:{
    progress:{
      type: Number,
      default:0
    },
    text:{
      type: String,
      default: ''
    }
  },
  data(){
    return{
      content:''
    }
  },
  mounted(){
    if(this.text.length == 0) this.content = this.$t('suite_study_progress_default')
    else this.content = this.text
  }
})
